import React, { FC, Suspense, useMemo } from 'react';
import Image from 'next/image';
import get from 'lodash/get';

import useWindowResize from '@source/hooks/useWindowResize';
import { WORKSHOP_BANNER } from '@source/pages/Refurb/constants';
import { TRegionKey } from '@source/interface';
import {
  StyledContainerWrapper,
  StyledContent,
  StyledFormWrapper,
  StyledImageOverlay,
  StyledLeftBanner,
  StyledRow,
  StyledTitle,
  StyledWrapper,
} from '@source/pages/Refurb/components/Banner/style';
import { useTranslation } from 'react-i18next';
import ServiceQuoteFormContainer from '@source/pages/Refurb/containers/ServiceQuoteWrapperContainer';

interface WorkshopBannerProps {
  country: TRegionKey;
}

const WorkshopBanner: FC<WorkshopBannerProps> = ({ country }) => {
  const { titleKey, ...bgImgProps } = WORKSHOP_BANNER;

  const { t } = useTranslation();
  const [width] = useWindowResize();

  const isAboveTablet = width && width > 991;
  const isBelowTablet = width && width <= 991;

  const expanded = false;

  const bgKeyPath = useMemo(() => {
    let bgKey = null;
    const supportedScreenSizes = [375, 576, 768, 992, 1280, 1920];

    for (let index = 0; index < supportedScreenSizes.length; index += 1) {
      const ele = supportedScreenSizes[index];

      if (!width) bgKey = 'bgImg375';

      if (width <= supportedScreenSizes[0]) {
        bgKey = 'bgImg375';
        break;
      }
      if (width >= supportedScreenSizes[supportedScreenSizes.length - 1]) {
        bgKey = 'bgImg1920';
        break;
      }

      const nextEle = supportedScreenSizes[index + 1];

      if (width >= ele && width < nextEle) {
        bgKey = `bgImg${ele}`;
      }
    }

    return `${bgKey}${expanded && country !== 'id' ? 'Expanded' : ''}`;
  }, [width, expanded]);

  const imageSrc = get(bgImgProps, bgKeyPath);
  const blurSrc = get(bgImgProps, `${bgKeyPath}Blur`);

  return (
    <>
      <StyledWrapper country={country}>
        <Image
          alt="banner"
          src={imageSrc}
          layout="fill"
          objectFit="cover"
          objectPosition="top left"
          priority
          blurDataURL={blurSrc}
          placeholder="blur"
        />
        <StyledImageOverlay />
        <StyledContainerWrapper>
          <StyledContent>
            <StyledRow>
              <StyledLeftBanner expanded={false}>
                <StyledTitle>
                  <span>{t('refurb.banner.title')}</span>
                  <br />
                  <span className="txt--orange">{t('refurb.banner.secondaryTitle')}</span>
                </StyledTitle>
              </StyledLeftBanner>

              {isAboveTablet && (
                <StyledFormWrapper id="service-quote-form" expanded={false}>
                  <ServiceQuoteFormContainer country={country} />
                </StyledFormWrapper>
              )}
            </StyledRow>
          </StyledContent>
        </StyledContainerWrapper>
      </StyledWrapper>
      {isBelowTablet && (
        <Suspense fallback={<ServiceQuoteFormContainer country={country} />}>
          <ServiceQuoteFormContainer country={country} />
        </Suspense>
      )}
    </>
  );
};

export default WorkshopBanner;
