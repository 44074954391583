import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import isNil from 'lodash/isNil';

import { TRegionKey } from '@source/interface';
import { TitleValueProps } from '@source/types';
import { IFormItemProps } from '@source/interface/form';
import { StyledFormItem } from '@source/components/FormItems/FormItems';
import useScreenSize from '@source/hooks/useScreenSize';

import MobileModal from '@design-system/components/MobileModal/MobileModal';
import { DefaultOptionProps } from '@design-system/components/FiltersV1/FilterProps';
import LocationMap from '@source/pages/Refurb/components/LocationMapSelector/LocationMap';
import { LOCATION_MAP_MODAL_WIDTH } from '@source/pages/Refurb/constants';
import { getWorkShopLocationConfig } from '@source/pages/Refurb/utils';

interface LocationMapSelectorProps extends IFormItemProps {
  country: TRegionKey;
  options: TitleValueProps[] | null;
  open: boolean;
  onClick?: () => void;
  onChange?: (value: TitleValueProps['value'], option: TitleValueProps) => void;
  onClose?: () => void;
}

const LocationMapSelector: FC<LocationMapSelectorProps> = ({
  formItemProps,
  controlProps,
  country,
  options,
  open,
  onClick,
  onChange,
  onClose,
}) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const [isShowModal, setIsShowModal] = useState(false);

  const modalWidth = useMemo(() => LOCATION_MAP_MODAL_WIDTH[screenSize], [screenSize]);

  const handleClick = () => {
    if (isNil(open)) {
      setIsShowModal(true);
    }
    onClick?.();
  };

  const handleModelClose = () => {
    if (isNil(open)) {
      setIsShowModal(false);
    }
    onClose?.();
  };

  const handlePointClick = (point: TitleValueProps) => {
    handleModelClose();
    onChange?.(point?.value, point);
  };

  const renderOptions = () =>
    options?.map((option: DefaultOptionProps) => {
      const { selectTitle } = getWorkShopLocationConfig(country, option.name) || {};
      return (
        <Select.Option key={option.name} value={option.value} disabled={!!option.disabled}>
          {t(selectTitle || (option.title as string))}
        </Select.Option>
      );
    });

  return (
    <>
      {/* use select only for render UI */}
      <StyledFormItem {...formItemProps}>
        <Select {...controlProps} style={{ width: '100%' }} open={false} onClick={handleClick}>
          {renderOptions()}
        </Select>
      </StyledFormItem>
      <MobileModal
        title={t('refurb.serviceQuoteForm.form.location.modal.title')}
        width={modalWidth}
        visible={isNil(open) ? isShowModal : open}
        noHeaderBorder
        hasFooter={false}
        hasFixHeight={false}
        hasStickyHeader={false}
        maskClosable
        onClose={handleModelClose}
        // closable={false}
        // destroyOnClose
      >
        <LocationMap country={country} locations={options} onPointClick={handlePointClick} />
      </MobileModal>
    </>
  );
};

export default LocationMapSelector;
