import StyledContainer from '@design-system/styled/Container';
import { Button } from 'antd';
import styled from 'styled-components';

export const StyledContainerWrapper = styled(StyledContainer)`
  margin-left: unset;
  margin-right: unset;
  @media screen and (min-width: 992px) {
    flex: 1;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 20px;
  padding: 24px;
  width: 100%;

  @media screen and (max-width: 991px) {
    background: ${(props) => props.theme.color.background};
    z-index: 2;
    margin: 0 auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    transform: translateY(-120px);
    // set margin-bottom to negative number because i used translateY form to top.
    // The value is calculated by -(translateY) - (translateY (from below style))
    margin-bottom: calc(-120px - 16px);
  }

  .error-message {
    font-size: 12px;
    color: ${(props) => props.theme.color.errorHighEmphasis};
    margin-bottom: 10px;
  }

  .otp-countdown {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;

    .ant-statistic-content {
      font-weight: 400;
      color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    }
  }
`;

export const StyledSubmitBtn = styled(Button)`
  width: 100%;
  padding: 10px 20px;
  text-transform: capitalize;
  height: 42px;
  background: ${(props) => props.theme.color.primaryBase};
  box-shadow: 0px 4px 10px rgba(181, 65, 0, 0.5);
  border: none;
  border-radius: 12px;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  font-size: 14px;
  font-weight: 600;

  &[disabled] {
    background: ${(props) => props.theme.color.onBackgroundLowEmphasis};
    box-shadow: none;
    color: ${(props) => props.theme.color.background}; // #ffffff
  }

  &.ant-btn-primary {
    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.color.primaryBase};
      background: ${(props) => props.theme.color.primaryBase};
    }
  }
`;
