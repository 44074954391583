import { GetServerSideProps } from 'next';

import RefurbPage from '@source/pages/Refurb';

import { TLanguageKey, TRegionKey } from '@source/interface';
import { CARRO_MOBILE_APP_KEY, COOKIE_KEYS } from '@source/constants/common';
import { formatLanguageCodeInUrl, formatReqUrl, getCurrentRegion, getLanguageInURL, isMyTukarWeb } from '@source/utils/utils';

export const getServerSideProps: GetServerSideProps = async ({ locale, req, res }) => {
  const isFromMobileApp = req.cookies?.[CARRO_MOBILE_APP_KEY] === 'true';
  const isMY = isMyTukarWeb(req.headers.host as string);
  const cookieRegion = req.cookies?.[COOKIE_KEYS.STORED_REGION];
  const currentRegion = await getCurrentRegion({
    urlRegion: locale as TRegionKey,
    cookieRegion: cookieRegion as TRegionKey,
    isMY,
  });
  const urlLanguage = getLanguageInURL(formatReqUrl(req?.url as string, locale as TRegionKey));
  const lang = formatLanguageCodeInUrl(urlLanguage as TLanguageKey);

  if (currentRegion !== 'my') {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      region: currentRegion,
      isFromMobileApp,
      lang,
    },
  };
};

export default RefurbPage;
