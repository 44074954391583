import React, { FC } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { Trans, useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';

import { DIFFERENCE_SERVICES } from '@source/pages/Refurb/constants';
import useBreakPoint from '@design-system/hooks/useBreakPoint';

const Wrapper = styled.div`
  ${(props) => props.theme.typo.familyGoogle.regular}
  max-width: 1184px;
  margin: 48px auto;

  .section-title {
    text-align: center;
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
    color: ${(props) => props.theme.color.primaryBase};
  }

  .section-desc {
    margin-top: 24px;
    margin-bottom: 32px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  @media screen and (max-width: 1279px) {
    padding: 48px 48px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 768px) {
    .section-title {
      font-size: 28px;
      line-height: 40px;
    }

    .section-desc {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;
const ServiceWrapper = styled(Col)`
  .service-details {
    height: 350px;
    border-radius: 28px;
    padding-top: 45px;
    border: 2px solid #ffa580;
    background: #fff;
    box-shadow: 0px 5px 35px 0px rgba(34, 34, 35, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    text-align: center;

    .title {
      margin-top: 10px;
      font-weight: 600;
      font-size: 21px;
      line-height: 25px;
      text-transform: capitalize;
      color: ${(props) => props.theme.color.primaryBase};
    }

    .sub-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
    }

    .desc {
      font-size: 12px;
      line-height: 21px;
    }

    .notes {
      font-size: 10px;
      line-height: 14px;
    }
  }
`;

const DividerWrapper = styled(Divider)`
  margin: 48px 0;
  background: ${(props) => props.theme.color.primaryBase};
`;

const Differences: FC = () => {
  const { t } = useTranslation();

  const isMobileScreen = !useBreakPoint('sm');

  return (
    <Wrapper>
      <div className="section-title">{t('refurb.difference.sectionTitle')}</div>
      <div className="section-desc">
        <Trans
          i18nKey={isMobileScreen ? 'refurb.difference.sectionDescMobile' : 'refurb.difference.sectionDescDesktop'}
          components={{ br: <br /> }}
        />
      </div>
      <Row gutter={[32, 32]} align="middle" justify="center">
        {DIFFERENCE_SERVICES.map((service, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <ServiceWrapper key={`refurb-service-${idx}`} xl={8} md={12} xs={24}>
            <div className="service-details">
              <Image src={service.icon} width={100} height={100} />
              <div className="title">
                <Trans
                  i18nKey={service.title}
                  components={{
                    br: <br />,
                  }}
                />
              </div>
              <div className="sub-title">
                <Trans
                  i18nKey={service.subTitle}
                  components={{
                    br: <br />,
                  }}
                />
              </div>
              <div className="desc">
                <Trans
                  i18nKey={service.desc}
                  components={{
                    br: <br />,
                  }}
                />
              </div>
              {service.notes && (
                <div className="notes">
                  <Trans
                    i18nKey={service.notes}
                    components={{
                      br: <br />,
                    }}
                  />
                </div>
              )}
            </div>
          </ServiceWrapper>
        ))}
      </Row>
      <DividerWrapper />
    </Wrapper>
  );
};

export default Differences;
