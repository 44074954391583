import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { LocationPointProps, TitlePlacement } from '@source/pages/Refurb/interfaces';
import { KeepScale } from 'react-zoom-pan-pinch';
import Image from 'next/image';

import PinIcon from '@design-system/assets/generalIcons/myTukar-pin.svg';

const StyledPoint = styled.div<{ titlePlacement: TitlePlacement }>`
  cursor: pointer;
  overflow: visible;
  position: absolute;

  .pin {
    position: relative;

    & > span {
      overflow: visible !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -30%);
    }
  }

  .pointText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(15%, -120%);
    white-space: nowrap;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    ${(props) => props.theme.typo.style.smallButtonOrLink};
    line-height: 18px;
    fill: #222223;

    ${(props) =>
      props.titlePlacement === TitlePlacement.left &&
      css`
        left: unset;
        right: 50%;
        transform: translate(-15%, -130%);
      `}
  }
`;

const PLACEMENT_POS: { [key in TitlePlacement]: { x: number; y: number } } = {
  [TitlePlacement.top]: { x: 0, y: 0 },
  [TitlePlacement.right]: { x: 42, y: 25 },
  [TitlePlacement.bottom]: { x: 0, y: 0 },
  [TitlePlacement.left]: { x: 0, y: 25 },
};

const LocationPoint: FC<LocationPointProps> = ({
  title,
  point,
  titlePlacement = TitlePlacement.right,
  styles,
  onClick,
}: LocationPointProps) => {
  const { width = '147', height = '51', top = 0, left = 0 } = styles || {};
  const titlePos: { x: number; y: number } = PLACEMENT_POS[titlePlacement];
  return (
    <StyledPoint titlePlacement={titlePlacement} style={{ top, left }} onClick={onClick}>
      <KeepScale className="keepScale">
        <div className="pin">
          <Image src={PinIcon} />

          <div className="pointText">{title || point?.title}</div>
        </div>
      </KeepScale>
    </StyledPoint>
  );
};

export default LocationPoint;
