import * as actions from '@source/redux/slice/workshopSlice';
import { useAppDispatch, useAppSelector } from '@source/hooks/useStateHook';
import { shallowEqual } from 'react-redux';
import { useCallback } from 'react';
import { TRegionKey } from '@source/interface';
import { useRouter } from 'next/router';
import { getRecaptchaToken } from '@design-system/utils/utils';
import { useTranslation } from 'react-i18next';

const useWorkShopServices = () => {
  const dispatch = useAppDispatch();
  const { isReady, pathname } = useRouter();
  const { i18n } = useTranslation();

  /** Selectors */
  const workshopState = useAppSelector((state) => state.workshop, shallowEqual);

  const getWorkshopServices = useCallback(
    ({ include }: { include?: string[] } = {}) => dispatch(actions.getWorkshopServices({ queryParams: { include } })),
    [dispatch],
  );

  const getWorkshopLocations = useCallback(
    (payload?: Record<string, any>) => dispatch(actions.getWorkShopLocations({ payload })),
    [dispatch],
  );

  const createWorkshopTicket = useCallback(
    ({
      country,
      payload,
      isAuthenticated = false,
    }: {
      country: TRegionKey;
      payload: Record<string, unknown>;
      isAuthenticated?: boolean;
    }) => {
      const currentPage = pathname.replace(/[^a-zA-Z]/g, '');
      const recaptchaAction = `${country}_${currentPage}_requestOtp`;

      const createWorkshopTicketWithToken = (token: string) =>
        dispatch(
          actions.createWorkshopTicket({
            country,
            payload: {
              ...payload,
              g_recaptcha_response: token,
              g_recaptcha_action: recaptchaAction,
            } as Record<string, unknown>,
            isAuthenticated,
          }),
        );
      return getRecaptchaToken(createWorkshopTicketWithToken, createWorkshopTicketWithToken, recaptchaAction);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isReady, i18n.language],
  );

  return {
    workshopState,
    getWorkshopServices,
    getWorkshopLocations,
    createWorkshopTicket,
  };
};

export default useWorkShopServices;
