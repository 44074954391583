import { Grid } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

const { useBreakpoint } = Grid;

const useScreenSize = (): string => {
  const screens = useBreakpoint();
  const BPInOrder: Breakpoint[] = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];
  return BPInOrder.find((size) => screens[size]) || BPInOrder[BPInOrder.length - 1];
};

export default useScreenSize;
