import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Col, Form, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import i18next from 'i18next';
import {
  StyledContainerWrapper,
  StyledSubmitBtn,
  StyledWrapper,
} from '@source/pages/Refurb/containers/ServiceQuoteFormContainer.style';
import ServiceQuoteFormDetail from '@source/pages/Refurb/components/ServiceQuoteForm/ServiceQuoteFormDetail';
import ServiceQuoteForm from '@source/pages/Refurb/components/ServiceQuoteForm/ServiceQuoteForm';
import { getDeviceInfo, getSource } from '@source/utils/utils';
import useViewedCars from '@source/hooks/useViewedCars';
import { ICreateWorkshopTicketRes } from '@source/interface/refurb';
import useGTMEvent from '@source/hooks/useGTMEvent';
import useUserProfileServices from '@source/hooks/useUserProfileServices';
import useWorkShopServices from '@source/hooks/useWorkshopServices';
import { URL_CONFIG } from '@source/constants/urlConfig';
import { TRegionKey } from '@source/interface';
import { formatPhoneNumberWithCountryCode } from '@source/pages/CarDetail/utils';
import { getCreateTicketPayloadFormatted } from '../utils';
import ResultModal from '../components/ServiceQuoteForm/ResultModal';

const ServiceQuoteFormContainer = ({ country }: { country: TRegionKey }) => {
  const { t } = useTranslation();
  const router = useRouter();
  // const { getViewedCars } = useViewedCars();
  const { pushGTMEvent } = useGTMEvent();
  const { isAuthenticated } = useUserProfileServices();
  const { createWorkshopTicket } = useWorkShopServices();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submittedResultType, setSubmittedResultType] = useState<'success' | 'fail' | null>(null);

  const { make, model, submodel, year, service, centre } = router.query as { [key: string]: string };
  const autoFillValues = { make, model, submodel, year, service, centre };

  const handleSubmitWSTicketSuccess = (data: any, variables: any) => {
    setSubmitLoading(false);
    // after call sell ticket success, call fetchProfile to get latest profile info
    const { name: sellName, phone: sellPhone } = variables?.payload?.contact || {};

    pushGTMEvent({
      event: 'submitted_workshop_service_quotation',
      page_name: 'workshop',
      ticket_id: data?.id,
      enhanced_conversion_data: {
        phone_number: formatPhoneNumberWithCountryCode(sellPhone, country),
        name: sellName,
      },
    });

    setSubmitLoading(false);
    setSubmittedResultType('success');
  };

  const handleSubmitWSTicketFailed = () => {
    setSubmitLoading(false);
    setSubmittedResultType('fail');
  };

  const handleOnFormFinish = async (_: string, { values, forms }: { values: any; forms: any }) => {
    const formName = Object.keys(forms).find((i) => i !== 'submit-btn');
    const formInstance = forms?.[formName as keyof typeof forms];
    let fieldValues = {} as Record<string, any>;

    try {
      fieldValues = await formInstance.validateFields();
    } catch (error) {
      return;
    }

    if (isEmpty(fieldValues)) return;

    setSubmitLoading(true);

    // const viewedCars = getViewedCars(country);
    const sourceData = getSource(country);
    const deviceInfo = getDeviceInfo('');

    const payload = {
      ...getCreateTicketPayloadFormatted(fieldValues),
      // additional_data: {
      //   viewed_cars: viewedCars,
      // },
      tracking_data: {
        ...deviceInfo,
        ...sourceData,
      },
    } as Record<string, any>;

    const createTicketRes = (await createWorkshopTicket({
      country,
      payload,
      isAuthenticated: isAuthenticated(),
    })) as { payload: ICreateWorkshopTicketRes };

    const { ticket: ticketData } = createTicketRes?.payload || {};

    if (ticketData?.id) {
      formInstance.resetFields();
      handleSubmitWSTicketSuccess({ id: ticketData?.id }, { payload });
    } else {
      handleSubmitWSTicketFailed();
    }
  };

  const handleCloseResultModal = () => {
    setSubmittedResultType(null);
    if (submittedResultType === 'success') {
      router.replace(URL_CONFIG({ region: country as TRegionKey, lang: i18next.language }).workshop, undefined, {
        shallow: true,
      });
    }
  };

  return (
    <StyledContainerWrapper disables={['lg', 'xl', 'xxl']}>
      <StyledWrapper id="request-form-container">
        <ServiceQuoteFormDetail
          title={t('refurb.serviceQuoteForm.title')}
          desc={<Trans i18nKey="refurb.serviceQuoteForm.desc" />}
        />
        <Form.Provider onFormFinish={handleOnFormFinish}>
          <Row>
            <Col xs={24}>
              <ServiceQuoteForm autoFillValues={autoFillValues} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form name="submit-btn">
                <StyledSubmitBtn type="primary" htmlType="submit" loading={submitLoading}>
                  {t('refurb.serviceQuoteForm.submitBtn')}
                </StyledSubmitBtn>
              </Form>
            </Col>
          </Row>
        </Form.Provider>
        <ResultModal type={submittedResultType} visible={!!submittedResultType} onClose={handleCloseResultModal} />
      </StyledWrapper>
    </StyledContainerWrapper>
  );
};

export default ServiceQuoteFormContainer;
