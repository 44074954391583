import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { WORKSHOP_FORM_INFO } from '@source/pages/Refurb/constants';
import KeyPoints from '@source/pages/Refurb/components/ServiceQuoteForm/KeyPoints';
import { TRegionKey } from '@source/interface';
import ServiceQuoteFormContainer from './ServiceQuoteFormContainer';

const StyledWrapper = styled.div`
  width: auto;
  background: ${(props) => props.theme.color.background};

  display: flex;
  border-radius: 12px 12px 12px 12px;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    transform: translateY(-16px);
    width: 100%;
  }
`;

const ServiceQuoteFormWrapperContainer = ({ country }: { country: TRegionKey }) => {
  const { t } = useTranslation();
  const { icons } = WORKSHOP_FORM_INFO;
  const keyPoints = [
    {
      iconUrl: icons.quanlityParts,
      title: t('refurb.keyPoints.quanlityParts.title'),
      desc: <Trans i18nKey="refurb.keyPoints.quanlityParts.desc" />,
    },
    {
      iconUrl: icons.upfrontPricing,
      title: t('refurb.keyPoints.upfrontPricing.title'),
      desc: <Trans i18nKey="refurb.keyPoints.upfrontPricing.desc" />,
    },
    {
      iconUrl: icons.quickGood,
      title: t('refurb.keyPoints.quickGood.title'),
      desc: <Trans i18nKey="refurb.keyPoints.quickGood.desc" />,
    },
  ];

  return (
    <StyledWrapper>
      <KeyPoints content={keyPoints} />
      <ServiceQuoteFormContainer country={country} />
    </StyledWrapper>
  );
};

export default ServiceQuoteFormWrapperContainer;
