import React, { FC, useState } from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { myTukarCenterAddress } from '@source/pages/Refurb/constants';
import { CloseOutlined } from '@ant-design/icons';

type ServiceCenterAddressProps = {
  centerName: string;
};
const Wrapper = styled(Popover)``;
const PopOverContent = styled.div`
  ${props => props.theme.typo.familyGoogle.regular}

  .center-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }

  .address-title,
  .tel-title,
  .opening-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.14px;
    margin-bottom: 4px;
  }

  .address,
  .tel,
  .opening {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.14px;
    margin-bottom: 12px;
  }

  a {
    color: #0075ff;
  }
`;

const ServiceCenterAddress: FC<ServiceCenterAddressProps> = ({ centerName }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const center = myTukarCenterAddress[centerName as keyof typeof myTukarCenterAddress];

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const renderContent = (
    <PopOverContent>
      <div className="center-name">
        <div>{t(center.name)}</div>
        <CloseOutlined style={{ fontSize: '24px' }} onClick={hide} />
      </div>
      <div className="address-title">{t('refurb.visitUs.address')}</div>
      <div className="address">
        <Trans
          i18nKey={center.address}
          components={{
            div: <div />,
            br: <br />,
          }}
        />
      </div>
      <div className="tel-title">{t('refurb.visitUs.tel')}</div>
      <div className="tel">
        <Trans
          i18nKey={center.tel}
          components={{
            div: <div />,
            br: <br />,
          }}
        />
      </div>
      <div className="opening-title">{t('refurb.visitUs.openingHours')}</div>
      <div className="opening">
        <Trans
          i18nKey={center.openingHours}
          components={{
            div: <div />,
            br: <br />,
          }}
        />
      </div>
      <a href={center.viewInMap} target="_blank" className="view-map" rel="noreferrer">
        {t('refurb.visitUs.viewInMap')}
      </a>
    </PopOverContent>
  );
  return (
    <Wrapper
      overlayInnerStyle={{ padding: '12px 8px', maxHeight: '300px', maxWidth: '311px', borderRadius: '20px' }}
      content={renderContent}
      title=""
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <span>{t('refurb.visitUs.viewAddress')}</span>
    </Wrapper>
  );
};

export default ServiceCenterAddress;
