/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { SERVICES_DATA_MOBILE } from '@source/pages/Refurb/constants';
import { formatServicePrice } from '@source/pages/Refurb/utils';

const Wrapper = styled.div`
  border-radius: 28px;
  background: #ffede5;
  padding: 36px 20px;

  .table {
    border-bottom: 1px solid ${(props) => props.theme.color.primaryBase};
  }

  .table-header {
    padding: 16px 0px;
    border-top: 1px solid ${(props) => props.theme.color.primaryBase};
    border-bottom: 1px solid ${(props) => props.theme.color.primaryBase};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .table-header-title {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
    }

    .table-header-desc {
      margin-top: 4px;
      font-size: 8px;
      line-height: 12px;
    }

    .ant-col {
      &:first-child {
        text-align: left;
        padding-left: 4px;
      }
    }
  }

  .table-data {
    font-size: 12px;
    line-height: 14px;
    padding-top: 24px;
    padding-bottom: 32px;
    text-align: center;

    .ant-row {
      .ant-col {
        &:first-child {
          text-align: left;
        }
      }
    }

    .data-value {
      padding: 8px 4px;
      border-right: 1px solid #bcbcbd;

      &:last-child {
        border-right: none;
      }
    }
  }

  .section-footer {
    margin-top: 16px;

    .terms {
      font-size: 8px;
      line-height: 12px;
    }
  }
`;

const DataTableMobile: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div className="table">
        {SERVICES_DATA_MOBILE.map((service, idx) => (
          <>
            <Row className="table-header">
              {service.headers.map((header) => (
                <Col key={`services-data-header-${header.name}`} span={header.colSpan}>
                  <div className="table-header-title">{t(header.name)}</div>
                  {header.desc && <div className="table-header-desc">{t(header.desc)}</div>}
                </Col>
              ))}
            </Row>
            <div className="table-data">
              {service.data.map((item, itemIdx) => (
                <Row key={`table-data-${itemIdx}`}>
                  {Object.values(item).map((value, index) => (
                    <Col className="data-value" span={service.headers[index].colSpan}>
                      {index === 0 ? t(item.category) : formatServicePrice(value)}
                    </Col>
                  ))}
                </Row>
              ))}
            </div>
          </>
        ))}
      </div>
      <div className="section-footer">
        <div className="terms">{t('refurb.services.table.footer.termsAndConditions.title')}</div>
      </div>
    </Wrapper>
  );
};

export default DataTableMobile;
