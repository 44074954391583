import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useBreakPoint from '@design-system/hooks/useBreakPoint';
import DataTableDesktop from './DataTableDesktop';
import DataTableMobile from './DataTableMobile';

const GreatServicesWrapper = styled.div`
  ${props => props.theme.typo.familyGoogle.regular}
  max-width: 1184px;
  margin: 0 auto 48px;

  .section-title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: ${(props) => props.theme.color.primaryBase};
    margin-bottom: 32px;
  }

  @media screen and (max-width: 1279px) {
    padding: 0 48px 48px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 991px) {
    padding: 0 16px 24px;
  }

  @media screen and (max-width: 768px) {
    .section-title {
      font-size: 28px;
    }
  }
`;

const GreatServices: FC = () => {
  const { t } = useTranslation();
  const isMobileScreen = !useBreakPoint('lg');

  return (
    <GreatServicesWrapper>
      <div className="section-title">{t('refurb.services.sectionTitle')}</div>
      {isMobileScreen ? <DataTableMobile /> : <DataTableDesktop />}
    </GreatServicesWrapper>
  );
};

export default GreatServices;
