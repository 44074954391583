import React, { FC } from 'react';
import styled from 'styled-components';

import useBreakPoint from '@design-system/hooks/useBreakPoint';
import MapDesktop from '@source/pages/Refurb/components/VisitUs/MapDesktop';
import MapMobile from '@source/pages/Refurb/components/VisitUs/MapMobile';

const StyledWrapper = styled.div`
  background: #e8e8e9;
`;

const VisitUs: FC = () => {
  const isMobileScreen = !useBreakPoint('lg');

  return <StyledWrapper>{isMobileScreen ? <MapMobile /> : <MapDesktop />}</StyledWrapper>;
};

export default VisitUs;
