import React, { FC, ReactElement } from 'react';
import Image from 'next/image';
import { Divider, Grid } from 'antd';
import {
  StyledKeyItem,
  StyledKeyPointsWrapper,
  StyledKeyPoints,
} from '@source/pages/Refurb/components/ServiceQuoteForm/KeyPoints.style';

const { useBreakpoint } = Grid;

interface KeyPointsProps {
  content: Array<{
    iconUrl: string;
    title: string;
    desc: string | ReactElement;
  }>;
}
const KeyPoints: FC<KeyPointsProps> = ({ content }) => {
  const { lg: isLgBreakpoint } = useBreakpoint();

  return (
    <StyledKeyPointsWrapper>
      <StyledKeyPoints>
        {content.map(({ iconUrl, title, desc }, index) => (
          <>
            <StyledKeyItem>
              <div className="icon">
                <Image src={iconUrl} width="100%" height="100%" />
              </div>
              <div className="content">
                <div className="title">{title}</div>
                <div className="desc">{desc}</div>
              </div>
            </StyledKeyItem>
            {index !== content.length - 1 && <Divider plain type={isLgBreakpoint ? 'horizontal' : 'vertical'} />}
          </>
        ))}
      </StyledKeyPoints>
    </StyledKeyPointsWrapper>
  );
};

export default KeyPoints;
