import StyledContainer from '@design-system/styled/Container';
import styled from 'styled-components';

export const StyledKeyPointsWrapper = styled(StyledContainer)`
  width: 100%;
  height: auto;
  min-height: 260px;

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
    width: 190px;
    padding: 36px 24px;
  }

  ${(props) => props.theme.typo.familyGoogle.regular};
  background-color: ${(props) => props.theme.color.primaryBase};

  padding-top: 20px;
  padding-bottom: 120px;
  border-radius: 12px 12px 0px 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  .ant-divider-vertical {
    border-left: 1px solid ${(props) => props.theme.color.onPrimary};
    height: auto;
  }
  .ant-divider-horizontal {
    border-top: 1px solid ${(props) => props.theme.color.onPrimary};
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0;
  }
`;

export const StyledKeyPoints = styled.div`
  display: flex;
  flex-basis: 0;
  margin-bottom: 20px;

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px;
  }
`;

export const StyledKeyItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.color.onPrimary};
  gap: 8px;

  .icon {
    width: 40px;
    height: 40px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    text-align: center;

    .title {
      ${(props) => props.theme.typo.familyGoogle.semiBold};
      font-size: 14px;
      line-height: 18px;
      // text-wrap: nowrap;
    }

    .desc {
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      width: 104px;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
    gap: 8px;

    .icon {
      width: 64px;
      height: 64px;
    }

    .content {
      .title {
        ${(props) => props.theme.typo.familyGoogle.semiBold};
        font-size: 16px;
        line-height: 24px;
      }

      .desc {
        font-size: 11px;
        line-height: 14px;
        width: 100%;
      }
    }
  }
`;
