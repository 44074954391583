/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import * as actions from '@source/redux/slice/configOptionsSlice';
import { useAppDispatch, useAppSelector } from '@source/hooks/useStateHook';

import { TRegionKey } from '@source/interface';
import { ICountryProps } from '@source/interface/apiResponse';
import { IConfigOptionsPayload } from '@source/interface/configOptions';

const useConfigOptionsServices = () => {
  const dispatch = useAppDispatch();

  /** Selectors */
  const configOptionsState = useAppSelector((state) => state.configOptions, shallowEqual);

  /** Dispatched Funcs  */
  const getMakes = useCallback(
    ({ country, types = {} }: { country: TRegionKey; types?: { [key: string]: any } }) => {
      const configs: IConfigOptionsPayload = { types: [{ name: 'makes', ...types }] };
      return dispatch(actions.getConfigOptions({ country, configs }));
    },
    [dispatch],
  );

  const getModels = useCallback(
    ({
      country,
      makeId,
      isFamilyModel,
      types = {},
    }: {
      country: TRegionKey;
      makeId: number;
      isFamilyModel?: boolean;
      types?: { [key: string]: any };
    }) => {
      const typesNames = {
        models: 'models',
        familyModels: 'family_models',
      };

      const configs: IConfigOptionsPayload = {
        types: [{ name: isFamilyModel ? typesNames.familyModels : typesNames.models, filter: makeId, ...types }],
      };

      return dispatch(actions.getConfigOptions({ country, configs }));
    },
    [dispatch],
  );

  const getYears = useCallback(
    ({ country, modelId, isFamilyModel }: { country: TRegionKey; modelId: number; isFamilyModel: boolean }) => {
      const configs: IConfigOptionsPayload = {
        types: [{ name: 'manufacture_years', filter: modelId, filter2: isFamilyModel ? 'family-model' : 'model' }],
      };

      return dispatch(actions.getConfigOptions({ country, configs }));
    },
    [dispatch],
  );

  const getSubmodels = useCallback(
    ({
      country,
      modelId,
      manufactureYear,
      isFamilyModel,
    }: {
      country: TRegionKey;
      modelId: number;
      manufactureYear: number;
      isFamilyModel: boolean;
    }) => {
      const configs: IConfigOptionsPayload = {
        types: [
          {
            name: 'submodel_variants',
            filter: modelId,
            filter2: manufactureYear,
            filter3: isFamilyModel ? 'family-model' : 'model',
          },
        ],
      };
      return dispatch(actions.getConfigOptions({ country, configs }));
    },
    [dispatch],
  );

  const getCarViewingPoint = useCallback(
    ({ country }: ICountryProps) => {
      const configs: IConfigOptionsPayload = {
        types: [{ name: 'inspection_locations', filter2: 'appear_on_consumer_page' }],
      };
      return dispatch(actions.getConfigOptions({ country, configs }));
    },
    [dispatch],
  );

  const getInspectionProvinces = useCallback(
    ({ country }: ICountryProps) => {
      const configs: IConfigOptionsPayload = {
        types: [{ name: 'inspection_center_provinces' }],
      };
      return dispatch(actions.getConfigOptions({ country, configs }));
    },
    [dispatch],
  );
  const getInspectionLocationCities = useCallback(
    ({ country }: ICountryProps) => {
      const configs: IConfigOptionsPayload = {
        types: [{ name: 'inspection_location_cities', filter2: 'appear_on_consumer_page' }],
      };
      return dispatch(actions.getConfigOptions({ country, configs }));
    },
    [dispatch],
  );

  const getInspectionLocation = useCallback(
    ({ country, inspectionLocationCityId }: { country: TRegionKey; inspectionLocationCityId: number | null }) => {
      const configs: IConfigOptionsPayload = {
        types: [
          {
            name: 'inspection_locations',
            ...(inspectionLocationCityId ? { filter: inspectionLocationCityId } : {}),
            filter2: 'appear_on_consumer_page',
          },
        ],
      };
      return dispatch(actions.getConfigOptions({ country, configs }));
    },
    [dispatch],
  );

  const getMYInspectionLocation = useCallback(
    ({ country, provinceId }: { country: TRegionKey; provinceId: number | null }) =>
      dispatch(actions.getInspectionLocation({ country, provinceId })),
    [dispatch],
  );

  return {
    configOptionsState,
    getMakes,
    getModels,
    getYears,
    getSubmodels,
    getCarViewingPoint,
    getInspectionProvinces,
    getInspectionLocationCities,
    getInspectionLocation,
    getMYInspectionLocation,
  };
};

export default useConfigOptionsServices;
