import styled from 'styled-components';
import { Typography } from '@design-system/components';

export const StyledDetail = styled.div`
  ${(props) => props.theme.typo.familyGoogle.regular}
`;

export const StyledTitle = styled(Typography)`
  ${(props) => props.theme.typo.familyGoogle.bold};
  color: ${(props) => props.theme.color.primaryBase};
`;

export const StyledDesc = styled(Typography)`
  display: block;
  margin-top: 8px;
  max-width: 230px;

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.sm}) {
    max-width: 100%;
  }
`;
