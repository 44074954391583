/* eslint-disable @next/next/no-img-element */
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ServiceCenterAddress from '@source/pages/Refurb/components/VisitUs/ServiceCenterAddress';
import { myTukarCenter } from '@source/pages/Refurb/constants';
import { getStaticCDN } from '@design-system/utils/utils';

type StylesAddressProps = {
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
  textAlign?: string;
};

const Wrapper = styled.div`
  ${props => props.theme.typo.familyGoogle.regular}
  margin: 0 auto;
  max-width: 1184px;
  height: 1036px;
  padding-top: 70px;
  padding-bottom: 92px;
  position: relative;

  img {
    max-width: 732px;
    max-height: 874px;
  }

  .map {
    float: right;

    .map-content {
      position: relative;
      min-width: 729px;
    }
  }

  @media only screen and (max-width: 1279px) {
    padding-right: 96px;
    padding-left: 96px;
  }
`;

const LabelSection = styled.div`
  position: absolute;
  top: 750px;

  .section-title {
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
    color: ${(props) => props.theme.color.primaryBase};
  }

  .section-desc {
    font-size: 20px;
    line-height: 28px;
  }

  @media only screen and (max-width: 1279px) {
    top: 800px;

    .section-title {
      font-size: 32px;
      line-height: 40px;
    }

    .section-desc {
      font-size: 14px;
      line-height: 22px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .section-title {
      font-size: 30px;
    }
  }
`;

const Address = styled.div<StylesAddressProps>`
  position: absolute;
  top: ${({ top }) => top || 'unset'};
  right: ${({ right }) => right || 'unset'};
  bottom: ${({ bottom }) => bottom || 'unset'};
  left: ${({ left }) => left || 'unset'};
  text-align: ${({ textAlign }) => textAlign || 'left'};

  .center-name {
    color: ${(props) => props.theme.color.primaryBase};
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
  }

  .center-name-ms {
    margin-bottom: 6px;
  }

  .sub-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 6px;
  }

  .sub-name-ms {
    margin-bottom: unset !important;
  }

  .view-address {
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    color: #0075ff;
  }
`;

const MapDesktop: FC = () => {
  const { i18n } = useTranslation();
  const mapImageSrc = getStaticCDN('/assets/my/refurb/updated_service_center_map_desktop_20231018.svg');
  const currentLang = i18n.language;
  let leftKey = 'leftEN';
  let topKey = 'topEN';

  switch (currentLang) {
    case 'en':
      leftKey = 'leftEN';
      topKey = 'topEN';
      break;
    case 'ms':
      leftKey = 'leftMS';
      topKey = 'topMS';
      break;
    default:
      leftKey = 'leftZHMY';
      topKey = 'topZHMY';
  }

  return (
    <Wrapper>
      <LabelSection>
        <div className="section-title">
          <Trans
            i18nKey="refurb.visitUs.sectionTitle"
            components={{
              br: <br />,
            }}
          />
        </div>
        <div className="section-desc">
          <Trans
            i18nKey="refurb.visitUs.sectionDesc"
            components={{
              br: <br />,
            }}
          />
        </div>
      </LabelSection>
      <div className="map">
        <div className="map-content">
          <img src={mapImageSrc} alt="map" />
          {myTukarCenter.map((center, idx) => {
            const desktopConfig = center.configPositionPopOver.desktop;

            return (
              <Address
                top={desktopConfig?.[topKey as keyof typeof desktopConfig] || desktopConfig.top}
                left={desktopConfig?.[leftKey as keyof typeof desktopConfig] || desktopConfig.left}
                textAlign={desktopConfig?.textAlign}
              >
                {currentLang === 'ms' ? (
                  <>
                    <div className="sub-name sub-name-ms">
                      <Trans
                        i18nKey={center.centerType}
                        components={{
                          br: <br />,
                        }}
                      />
                    </div>
                    <div className="center-name center-name-ms">
                      <Trans
                        i18nKey={center.centerName}
                        components={{
                          br: <br />,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="center-name">
                      <Trans
                        i18nKey={center.centerName}
                        components={{
                          br: <br />,
                        }}
                      />
                    </div>
                    <div className="sub-name">
                      <Trans
                        i18nKey={center.centerType}
                        components={{
                          br: <br />,
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="view-address">
                  <ServiceCenterAddress centerName={center.center} />
                </div>
              </Address>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

export default MapDesktop;
