import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Col, Result, Row } from 'antd';

import MobileModal from '@design-system/components/MobileModal/MobileModal';
import { Button, MobileDrawer } from '@design-system/components';

import useWindowResize from '@source/hooks/useWindowResize';

type ResultModalProps = {
  type: 'success' | 'fail' | null;
  visible: boolean;
  onClose: () => void;
  // eslint-disable-next-line react/require-default-props
  errors?: string | null;
};

export const StyledWrapper = styled.div``;

export const StyledResult = styled(Result)<{ type: ResultModalProps['type'] }>`
  padding: 0;
  width: 100%;

  height: 100%;
  min-height: 400px;
  display: flex;
  align-items: 'center';
  flex-direction: column;
  justify-content: center;

  .ant-result-icon {
    .anticon {
      color: ${(props) =>
        props.type === 'success' ? props.theme.color.successHighEmphasis : props.theme.color.errorHighEmphasis};
    }
  }

  .ant-btn {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;

    a {
      color: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .anticon {
      margin-right: 3px;

      font-size: 20px;
    }
  }

  .ant-result-title {
    ${(props) => props.theme.typo.style.mHeader};
    margin-bottom: 10px;

    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  }

  .ant-result-subtitle {
    ${(props) => props.theme.typo.style.body};
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  }
`;

const ResultModal = ({ type, visible, onClose, errors }: ResultModalProps) => {
  const { t } = useTranslation();
  const [width] = useWindowResize();

  const renderSuccess = () => (
    <StyledResult
      type={type}
      status="success"
      className="p-24"
      title={t('refurb.serviceQuoteForm.resultModal.success.title')}
      subTitle={t('refurb.serviceQuoteForm.resultModal.success.subTitle', { errorMessage: errors || '' })}
    />
  );

  const renderFail = () => (
    <StyledResult
      type={type}
      className="p-24"
      title={t('refurb.serviceQuoteForm.resultModal.fail.title')}
      subTitle={t('refurb.serviceQuoteForm.resultModal.fail.subTitle', { errorMessage: errors || '' })}
    />
  );
  const renderResult = () => {
    if (type === 'success') {
      return renderSuccess();
    }

    if (type === 'fail') {
      return renderFail();
    }

    return null;
  };

  const customFooter = () => (
    <div className="modal-footer pr-24 pl-24 pb-24" key={0}>
      <Row gutter={10} className="mt-8">
        <Col xs={24}>
          <Button type="primary" size="large" ghost block onClick={onClose}>
            {type === 'success'
              ? t('refurb.serviceQuoteForm.resultModal.success.closeBtnLabel')
              : t('refurb.serviceQuoteForm.resultModal.fail.closeBtnLabel')}
          </Button>
        </Col>
      </Row>
    </div>
  );

  return (
    <StyledWrapper>
      {width && width >= 992 && (
        <MobileModal
          visible={visible}
          // hasFooter={false}
          customFooter={customFooter}
          hasFixHeight={false}
          hasStickyHeader
          maskClosable
          width={515}
          hasHeader
          onClose={onClose}
          title={t('refurb.serviceQuoteForm.resultModal.title')}
          closable={false}
          maskStyle={{ background: 'rgb(33, 33, 33, 0.7)' }}
          destroyOnClose
        >
          {renderResult()}
        </MobileModal>
      )}

      {width && width < 992 && (
        <MobileDrawer
          title={t('refurb.serviceQuoteForm.resultModal.title')}
          visible={visible}
          // hasFooter={false}
          customFooter={customFooter}
          onClose={onClose}
          destroyOnClose
        >
          {renderResult()}
        </MobileDrawer>
      )}
    </StyledWrapper>
  );
};

export default ResultModal;
