/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { SERVICES_DATA_TABLE } from '@source/pages/Refurb/constants';
import { formatServicePrice } from '@source/pages/Refurb/utils';

const Wrapper = styled.div`
  border-radius: 28px;
  background: #ffede5;
  padding: 58px;

  .table-header {
    padding: 20px 0px;
    border-top: 1px solid ${(props) => props.theme.color.primaryBase};
    border-bottom: 1px solid ${(props) => props.theme.color.primaryBase};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .table-header-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }

    .table-header-desc {
      font-size: 9px;
      line-height: 14px;
    }

    .ant-col {
      &:first-child {
        text-align: left;
      }
    }

    .data-header {
      padding: 0px 4px;
    }
  }

  .table-data {
    font-size: 14px;
    line-height: 18px;
    padding-top: 32px;
    padding-bottom: 18px;
    text-align: center;
    border-bottom: 1px solid ${(props) => props.theme.color.primaryBase};

    .ant-row {
      .ant-col {
        &:first-child {
          text-align: left;
          padding: 8px 16px 8px 4px;
        }
      }
    }

    .data-value {
      padding: 8px 4px;
      border-right: 1px solid #bcbcbd;

      &:last-child {
        border-right: none;
      }
    }
  }

  .section-footer {
    margin-top: 16px;

    .terms {
      font-size: 9px;
      line-height: 14px;
    }

    /* ol {
      padding-left: 14px;
    } */
  }
`;

const ColWrapper = styled(Col)<{ width: number }>`
  display: block;
  flex: 0 0 ${(props) => `${props.width}%`};
  max-width: ${(props) => `${props.width}%`};
`;

const DataTableDesktop: FC = () => {
  const { t } = useTranslation();
  const { headers, data } = SERVICES_DATA_TABLE;

  return (
    <Wrapper>
      <Row className="table-header">
        {headers.map((header, idx) => (
          <ColWrapper className="data-header" key={`services-data-header-${idx}`} width={header.width}>
            <div className="table-header-title">{t(header.name)}</div>
            {header?.desc && <div className="table-header-desc">{t(header.desc)}</div>}
          </ColWrapper>
        ))}
      </Row>
      <div className="table-data">
        {data.map((item, idx) => (
          <Row key={`table-data-${idx}`}>
            {Object.values(item).map((value, index) => (
              <ColWrapper className="data-value" width={headers[index].width}>
                {index === 0 ? t(item.category) : formatServicePrice(value)}
              </ColWrapper>
            ))}
          </Row>
        ))}
      </div>
      <div className="section-footer">
        <div className="terms">{t('refurb.services.table.footer.termsAndConditions.title')}</div>
      </div>
    </Wrapper>
  );
};

export default DataTableDesktop;
