import React from 'react';

import Layout from '@source/components/Layout';
import HeadMeta from '@source/pages/Home/components/HeadMeta/HeadMeta';
import { IRegionProps, TRegionKey } from '@source/interface';
import Differences from '@source/pages/Refurb/components/Differences';
import GreatServices from '@source/pages/Refurb/components/GreatServices';
import VisitUs from '@source/pages/Refurb/components/VisitUs';
import WorkshopBanner from '@source/pages/Refurb/components/Banner';
import useUTM from '@source/hooks/useUTM';

interface RefurbPageProps extends IRegionProps {
  isFromMobileApp: boolean;
}
const RefurbPage = ({ region: countryCode, isFromMobileApp }: RefurbPageProps) => {
  // save UTM referrer to cookie
  useUTM();

  return (
    <Layout
      bannerContent={<WorkshopBanner country={countryCode} />}
      hideHeader={isFromMobileApp}
      hideFooter={isFromMobileApp}
      hideSocialChat={false}
    >
      <HeadMeta metaKey="workshop" region={countryCode as TRegionKey} />
      <Differences />
      <GreatServices />
      <VisitUs />
    </Layout>
  );
};

export default RefurbPage;
