import { Grid } from 'antd';

export default function useBreakPoint(screenSize: string) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const currentScreenList = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .map((screen) => screen[0]);

  if (currentScreenList.includes(screenSize)) return true;
  return false;
}
