import { TitleTypography } from '@design-system/components';
import StyledContainer from '@design-system/styled/Container';
import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div<{
  bgImg1920?: string;
  bgImg1920Expanded?: string;
  bgImg1280?: string;
  bgImg1280Expanded?: string;
  bgImg992?: string;
  bgImg992Expanded?: string;
  bgImg768?: string;
  bgImg576?: string;
  bgImg375?: string;
  country?: string;
  [key: string]: any;
}>`
  position: relative;
  width: 100%;
  height: auto;

  background: url(${(props) => props.bgImg1920Expanded}) no-repeat;
  background-size: cover;
  background-position: top;

  .container {
    margin: 0 auto;
  }

  @media (max-width: 1919px) {
    background: url(${(props) => props.bgImg1280Expanded}) no-repeat;
    background-position: top left;
    background-size: cover;
    height: 100%;

    .container {
      margin-left: min(128px, 30%);
      margin-right: min(43px, 30%);
    }
  }

  @media (max-width: 1279px) {
    min-height: var(--minBannerHeight);

    ${(props) =>
      props.bgImg992 &&
      css`
        background: url(${props.bgImg992Expanded}) no-repeat;
      `}

    background-position: top left;
    background-size: cover;

    .container {
      margin-left: 64px;
    }
  }

  @media (max-width: 991px) {
    height: 372px;

    ${(props) =>
      props.bgImg768 &&
      css`
        background: url(${props.bgImg768}) no-repeat;
      `}
    background-position: top left;
    background-size: cover;

    .container {
      margin: auto 48px;
    }
  }

  @media (max-width: 767px) {
    ${(props) =>
      props.bgImg768 &&
      css`
        background: url(${props.bgImg576}) no-repeat;
      `}
    background-position: top left;
    background-size: cover;

    .container {
      margin: auto 24px;
    }
  }

  @media (max-width: 575px) {
    ${(props) =>
      props.bgImg576 &&
      css`
        background: url(${props.bgImg375}) no-repeat;
      `}
    background-position: top left;
    background-size: cover;

    .container {
      margin: 0px;
      padding-left: 15px;
    }
  }

  @media (max-width: 374px) {
  }

  .container {
    height: 100%;
  }
`;
export const StyledImageOverlay = styled.div`
  :before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.24) 58.81%, rgba(0, 0, 0, 0) 100%);
  }
`;
export const StyledContainerWrapper = styled(StyledContainer)`
  height: 100%;

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.xl}) {
    width: 100%;
    padding-left: ${(props) => props.theme.metrics.containers.padding.lg};
    padding-right: ${(props) => props.theme.metrics.containers.padding.lg};
  }
`;

export const StyledContent = styled.div`
  position: relative;
  max-width: 1184px;
  width: 100%;
  margin: 0px auto;
  height: 100%;

  padding-top: 152px;

  @media screen and (max-width: 1919px) {
    padding-top: 40px;
  }

  @media screen and (max-width: 1279px) {
    padding-top: 48px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 0px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

export const StyledLeftBanner = styled.div<{ expanded: boolean }>`
  display: flex;
  align-items: flex-end;
  width: fit-content;

  margin-right: 83px;
  margin-bottom: 40px;

  @media screen and (max-width: 1919px) {
    margin-right: 163px;
  }

  @media screen and (max-width: 1279px) {
    margin-right: 73px;
    margin-bottom: 46px;
  }

  @media screen and (max-width: 991px) {
    height: auto;
    margin-bottom: calc(37px + 16px); // + 16px of translateY from Sell Request Form VSP
  }

  @media screen and (max-width: 767px) {
    height: auto;
    margin-bottom: calc(17px + 16px); // + 16px of translateY from Sell Request Form VSP
  }
`;

export const StyledTitle = styled(TitleTypography)`
  &&& {
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    color: ${(props) => props.theme.color.background};
    font-size: 30px;
    letter-spacing: 0.01em;
    line-height: 33px;
    word-spacing: 0.01em;
    max-width: 290px;
    text-wrap: wrap;
    margin-bottom: 0px;

    .txt--orange {
      color: ${(props) => props.theme.color.primaryBase};
      text-transform: none;
    }

    @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.sm}) {
      max-width: 100%;
    }

    @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
      font-size: 38px;
      line-height: 38px;
      max-width: 445px;
    }

    @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.xl}) {
      font-size: 48px;
      line-height: 48px;
      width: 426px;
    }
  }
`;

// Right side hero banner
export const StyledFormWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  align-items: flex-start;
  width: 650px;

  margin-bottom: ${(props) => (props.expanded ? '15px' : '152px')};

  @media (max-width: 1919px) {
    margin-bottom: ${(props) => (props.expanded ? '16px' : '16px')};
  }

  @media (max-width: 1279px) {
    margin-bottom: 47px;
  }

  @media (min-width: 1280px) {
    margin-bottom: ${(props) => (props.expanded ? '16px' : '40px')};
  }
`;
