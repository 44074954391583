import { useEffect, useState } from 'react';

const useIsIOS = () => {
  const [isIOS, setIOS] = useState(false);

  useEffect(() => {
    if (navigator) {
      setIOS(/(iPad|iPhone|iPod)/g.test(navigator.userAgent));
    }
  }, []);

  return isIOS;
};

export default useIsIOS;
