import React, { FC, ReactElement } from 'react';
import {
  StyledDetail,
  StyledTitle,
  StyledDesc,
} from '@source/pages/Refurb/components/ServiceQuoteForm/ServiceQuoteFormDetail.style';

interface ServiceQuoteFormDetailProps {
  title: string;
  desc: string | ReactElement;
}
const ServiceQuoteFormDetail: FC<ServiceQuoteFormDetailProps> = ({ title, desc }) => (
  <StyledDetail>
    <StyledTitle variant="mHeader">{title}</StyledTitle>
    <StyledDesc variant="body">{desc}</StyledDesc>
  </StyledDetail>
);
export default ServiceQuoteFormDetail;
