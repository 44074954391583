/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { myTukarCenterAddress } from '@source/pages/Refurb/constants';
import { CloseOutlined } from '@ant-design/icons';

type ServiceCenterAddressModalProps = {
  centerName: string;
};

const Wrapper = styled.div`
  ${props => props.theme.typo.familyGoogle.regular}

  .view-address {
    color: #0075ff;
    font-size: 12px;
    line-height: 14px;
  }
`;

const ModalContent = styled.div`
  ${props => props.theme.typo.familyGoogle.regular}

  .center-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }

  .address-title,
  .tel-title,
  .opening-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.12px;
    margin-bottom: 4px;
  }

  .address,
  .tel,
  .opening {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.12px;
    margin-bottom: 8px;
  }

  .view-map {
    color: #0075ff;
  }
`;

const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(161, 161, 161, 0.25);
  }
`;

const ServiceCenterAddressModal: FC<ServiceCenterAddressModalProps> = ({ centerName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const center = myTukarCenterAddress[centerName as keyof typeof myTukarCenterAddress];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderContent = (
    <ModalContent>
      <div className="center-name">
        <div>{t(center.name)}</div>
        <CloseOutlined style={{ fontSize: '24px' }} onClick={handleCancel} />
      </div>
      <div className="address-title">{t('refurb.visitUs.address')}</div>
      <div className="address">
        <Trans
          i18nKey={center.address}
          components={{
            div: <div />,
            br: <br />,
          }}
        />
      </div>
      <div className="tel-title">{t('refurb.visitUs.tel')}</div>
      <div className="tel">
        <Trans
          i18nKey={center.tel}
          components={{
            div: <div />,
            br: <br />,
          }}
        />
      </div>
      <div className="opening-title">{t('refurb.visitUs.openingHours')}</div>
      <div className="opening">
        <Trans
          i18nKey={center.openingHours}
          components={{
            div: <div />,
            br: <br />,
          }}
        />
      </div>
      <a href={center.viewInMap} className="view-map" target="_blank" rel="noreferrer">
        {t('refurb.visitUs.viewInMap')}
      </a>
    </ModalContent>
  );
  return (
    <Wrapper>
      <div className="view-address" onClick={showModal}>
        <Trans
          i18nKey="refurb.visitUs.viewAddressMobile"
          components={{
            br: <br />,
          }}
        />
      </div>
      <ModalWrapper open={isModalOpen} footer={null} onCancel={handleCancel} closable={false} centered width={260}>
        {renderContent}
      </ModalWrapper>
    </Wrapper>
  );
};

export default ServiceCenterAddressModal;
