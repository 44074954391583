/* eslint-disable @next/next/no-img-element */
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ServiceCenterAddressModal from '@source/pages/Refurb/components/VisitUs/ServiceCenterAddressModal';
import { myTukarCenter } from '@source/pages/Refurb/constants';
import { getStaticCDN } from '@design-system/utils/utils';

type StylesAddressProps = {
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
  textAlign?: string;
};

const Wrapper = styled.div`
  ${props => props.theme.typo.familyGoogle.regular}
  padding: 72px 112px;

  img {
    width: 349px;
    height: 518px;
  }

  .map {
    display: flex;
    align-items: center;
    justify-content: center;

    .map-content {
      position: relative;
      width: 349px;
      height: 518px;
    }
  }

  @media only screen and (max-width: 575px) {
    padding: 36px 24px;
  }
`;

const LabelSection = styled.div`
  text-align: center;
  margin-bottom: 42px;

  .section-title {
    font-size: 28px;
    font-weight: 700;
    color: ${(props) => props.theme.color.primaryBase};
  }

  .section-desc {
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: 42px;
  }
`;

const Address = styled.div<StylesAddressProps>`
  position: absolute;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  top: ${({ top }) => top || 'unset'};
  right: ${({ right }) => right || 'unset'};
  bottom: ${({ bottom }) => bottom || 'unset'};
  left: ${({ left }) => left || 'unset'};

  .center-name {
    color: ${(props) => props.theme.color.primaryBase};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  .sub-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 2px;
  }

  .view-address {
    cursor: pointer;
    font-size: 12px;
    color: #0075ff;
    line-height: 14px;
  }
`;

const MapMobile: FC = () => {
  const { t } = useTranslation();
  const mapImageSrc = getStaticCDN('/assets/my/refurb/new_service_center_map_mobile_20231018.svg');
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  let leftKey = 'leftEN';
  let topKey = 'topEN';

  switch (currentLang) {
    case 'en':
      leftKey = 'leftEN';
      topKey = 'topEN';
      break;
    case 'ms':
      leftKey = 'leftMS';
      topKey = 'topMS';
      break;
    default:
      leftKey = 'leftZHMY';
      topKey = 'topZHMY';
  }

  return (
    <Wrapper>
      <LabelSection>
        <div className="section-title">
          <Trans
            i18nKey="refurb.visitUs.sectionTitle"
            components={{
              br: <br />,
            }}
          />
        </div>
        <div className="section-desc">{t('refurb.visitUs.sectionDescMobile')}</div>
      </LabelSection>
      <div className="map">
        <div className="map-content">
          <img src={mapImageSrc} alt="map" />
          {myTukarCenter.map((center, idx) => {
            const mobileConfig = center.configPositionPopOver.mobile;
            return (
              <Address
                top={mobileConfig?.[topKey as keyof typeof mobileConfig] || mobileConfig.top}
                left={mobileConfig?.[leftKey as keyof typeof mobileConfig] || mobileConfig.left}
                textAlign={mobileConfig?.textAlign}
              >
                {currentLang === 'ms' ? (
                  <>
                    <div className="sub-name">
                      <Trans
                        i18nKey={center.centerTypeMobile}
                        components={{
                          br: <br />,
                        }}
                      />
                    </div>
                    <div className="center-name">
                      <Trans
                        i18nKey={center.centerNameMobile}
                        components={{
                          br: <br />,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="center-name">
                      <Trans
                        i18nKey={center.centerNameMobile}
                        components={{
                          br: <br />,
                        }}
                      />
                    </div>
                    <div className="sub-name">
                      <Trans
                        i18nKey={center.centerTypeMobile}
                        components={{
                          br: <br />,
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="view-address">
                  <ServiceCenterAddressModal centerName={center.center} />
                </div>
              </Address>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

export default MapMobile;
